@font-face {
    font-family: "opensans-regular";
    src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
    src:
        url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "opensans-bold";
    src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
    src:
        url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"),
        url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden] {
    display: none;
}
html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}
button,
html,
input,
select,
textarea {
    font-family: Arial, Helvetica, sans-serif;
}
body {
    margin: 0;
    padding: 0;
}
:focus {
    outline: none;
}
a:active,
a:hover {
    outline: 0;
}
abbr[title] {
    border-bottom: 1px dotted;
}
b,
strong {
    font-weight: bold;
}
blockquote {
    margin: 1em 40px;
}
dfn {
    font-style: italic;
}
mark {
    background: #ff0;
    color: #000;
}
p,
pre {
    margin: 1em 0;
}
code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    font-size: 1em;
}
pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}
q {
    quotes: none;
}
q:after,
q:before {
    content: "";
    content: none;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
dl,
menu,
ol,
ul {
    margin: 1em 0;
}
dd {
    margin: 0 0 0 40px;
}
menu,
ol,
ul {
    padding: 0 0 0 40px;
}
nav ol,
nav ul {
    list-style: none;
    list-style-image: none;
}
img {
    border: 0;
    -ms-interpolation-mode: bicubic;
}
embed,
img {
    max-width: 100%;
}
iframe {
    border: none;
}
svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 0;
}
form {
    margin: 0;
}
fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    border: 0;
    padding: 0;
    white-space: normal;
}
button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
}
button,
input {
    line-height: normal;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled],
input[disabled] {
    cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
input[type="search"] {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a {
    color: #dc0032;
}
a:focus,
a:hover {
    color: #ff001e;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}
body {
    font:
        120%/1.5 Arial,
        Helvetica;
}
body.font-1 {
    font-size: 100%;
}
body.font-3 {
    font-size: 130%;
}
p {
    line-height: 1em;
    font-size: 0.875em;
    margin-bottom: 1em;
}
h1 {
    line-height: 2em;
    font-size: 1.5em;
    margin-bottom: 1em;
}
h2 {
    line-height: 2em;
    font-size: 1.25em;
    margin-bottom: 1em;
}
h3 {
    line-height: 2em;
    font-size: 1.125em;
}
h4 {
    line-height: 2em;
    font-size: 1em;
}
h5 {
    line-height: 2em;
    font-size: 0.875em;
    font-weight: bold;
}
h6 {
    line-height: 2em;
    font-size: 0.875em;
    font-weight: bold;
}
blockquote,
li,
p {
    margin: 0;
}
.imageWrapper a,
figure a {
    display: block;
}
label {
    color: #48535f;
    line-height: 30px;
}
input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="url"] {
    height: 28px;
    line-height: 28px;
    padding: 0 0.5em;
    font-size: 15px;
    border: 1px #cad5df solid;
    color: #98a3af;
    background: #fff;
    -moz-border-radius: 1px;
    -webkit-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 white;
    -moz-box-shadow: 0 1px 0 white;
    box-shadow: 0 1px 0 white;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="email"]:focus,
input[type="url"]:focus {
    border-color: #b6c1cc;
    color: #848f9b;
    -webkit-box-shadow: 0 1px 0 white;
    -moz-box-shadow: 0 1px 0 white;
    box-shadow: 0 1px 0 white;
}
input[type="text"].err,
input[type="password"].err,
input[type="search"].err,
input[type="email"].err,
input[type="url"].err {
    border-color: #dc0032;
    background-color: #ffccd2;
    color: #343f4b;
}
input[placeholder] {
    color: #98a3af;
    line-height: 28px;
}
a.button,
button,
input[type="submit"],
input[type="reset"] {
    -webkit-appearance: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    height: 28px;
    min-width: 30px;
    border: 1px #dc0032 solid;
    font-size: 15px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: white;
    text-shadow: 0 -1px 0 #64001e;
    background-color: #ff001e;
}
a.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
a.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus {
    background-color: #dc0032;
}
a.button:active,
button:active,
input[type="submit"]:active,
input[type="reset"]:active {
    background-color: #dc0032;
}
a.button.bright,
button.bright,
input[type="submit"].bright,
input[type="reset"].bright {
    color: #707b87;
    border-color: #cad5df;
    text-shadow: 0 1px 0 white;
    background-color: #fbfdfd;
}
a.button.bright:hover,
button.bright:hover,
input[type="submit"].bright:hover,
input[type="reset"].bright:hover,
a.button.bright:focus,
button.bright:focus,
input[type="submit"].bright:focus,
input[type="reset"].bright:focus {
    background-color: #dee6ee;
}
a.button.bright:active,
button.bright:active,
input[type="submit"].bright:active,
input[type="reset"].bright:active {
    background-color: #dee6ee;
}
a.button.dark,
button.dark,
input[type="submit"].dark,
input[type="reset"].dark {
    color: white;
    border-color: #48535f;
    background-color: #48535f;
    text-shadow: 0 -1px 0 #343f4b;
}
a.button.dark:hover,
button.dark:hover,
input[type="submit"].dark:hover,
input[type="reset"].dark:hover,
a.button.dark:focus,
button.dark:focus,
input[type="submit"].dark:focus,
input[type="reset"].dark:focus {
    background-color: #48535f;
}
a.button.dark:active,
button.dark:active,
input[type="submit"].dark:active,
input[type="reset"].dark:active {
    background-color: #48535f;
}
a.button,
button,
input[type="submit"],
input[type="reset"] {
    background: url("../../../images/ahacz/buttons.png") repeat-x;
    background-position: 0 0;
}
a.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
a.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus {
    background-position: 0 -30px;
}
a.button:active,
button:active,
input[type="submit"]:active,
input[type="reset"]:active {
    background-position: 0 -60px;
}
a.button.bright,
button.bright,
input[type="submit"].bright,
input[type="reset"].bright {
    background-position: 0 -90px;
}
a.button.bright:hover,
button.bright:hover,
input[type="submit"].bright:hover,
input[type="reset"].bright:hover,
a.button.bright:focus,
button.bright:focus,
input[type="submit"].bright:focus,
input[type="reset"].bright:focus {
    background-position: 0 -120px;
}
a.button.bright:active,
button.bright:active,
input[type="submit"].bright:active,
input[type="reset"].bright:active {
    background-position: 0 -150px;
}
a.button.dark,
button.dark,
input[type="submit"].dark,
input[type="reset"].dark {
    background-position: 0 -180px;
}
a.button.dark:hover,
button.dark:hover,
input[type="submit"].dark:hover,
input[type="reset"].dark:hover,
a.button.dark:focus,
button.dark:focus,
input[type="submit"].dark:focus,
input[type="reset"].dark:focus {
    background-position: 0 -210px;
}
a.button.dark:active,
button.dark:active,
input[type="submit"].dark:active,
input[type="reset"].dark:active {
    background-position: 0 -240px;
}
body {
    color: #343f4b;
    background: transparent url("../../../images/ahacz/bg.png") repeat 0 0;
    overflow-anchor: none;
}
body.gallery {
    background: #333f48 none;
}
#site-wrapper {
    width: 100%;
    position: relative;
    max-width: 480px;
    margin: 0 auto;
    padding-bottom: 60px;
}
.clean #site-wrapper {
    max-width: none;
}
#main-header {
    background: #ff0000;
    position: relative;
}
.gallery #main-header {
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    z-index: 900;
}
#main-header #headerForms {
    width: 100%;
    bottom: 0;
    display: none;
}
#main-header #headerForms input.submit {
    float: right;
    width: 23%;
    margin-right: 1%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 5px;
}
#main-header #headerForms input.search {
    width: 73%;
    margin-left: 1%;
    padding-left: 1%;
    padding-right: 0;
    margin-bottom: 5px;
}
#main-header .fontSizeSwitch {
    position: absolute;
    right: 72px;
    top: 24px;
}
#main-header .fontSizeSwitch span {
    color: white;
    text-decoration: none;
    padding: 5px;
    cursor: pointer;
}
#main-header .fontSizeSwitch span.active {
    text-decoration: underline;
}
#main-header .fontSizeSwitch span.font-1 {
    font-size: 16px;
}
#main-header .fontSizeSwitch span.font-2 {
    font-size: 20px;
}
#main-header .fontSizeSwitch span.font-3 {
    font-size: 24px;
}
#main-header .searchIco {
    width: 32px;
    height: 32px;
    background: transparent url("../../../images/ahacz/mobile/search-icon.png") no-repeat;
    position: absolute;
    right: 24px;
    top: 24px;
}
#mainSearch input.submit {
    height: 30px;
}
h1.formInfo {
    font-size: 1em;
    font-weight: 400;
}
#main-menu {
    position: absolute;
    position: fixed;
    z-index: 999;
    left: 0;
    bottom: 0;
    background: transparent url("../../../images/ahacz/bg.png") repeat 0 0;
    width: 100%;
    opacity: 0.95;
    -webkit-box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.3);
}
#main-menu .icon {
    display: block;
    margin: 5px;
}
#main-menu .ribbon {
    display: none;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -10px;
    left: 0;
    background: transparent url("../../../images/ahacz/ribbon-left-red.png") no-repeat 0 0;
}
#main-menu nav .menu .icon {
    height: 36px;
    width: 36px;
    background: transparent url("../../../images/ahacz/elements.png?v=12") no-repeat 0 0;
    margin: 0 auto;
}
#main-menu nav .menu .zhave-drby .icon {
    background-position: 0 0;
}
#main-menu nav .menu .trapasy .icon {
    background-position: 0 -36px;
}
#main-menu nav .menu .musite-vedet .icon {
    background-position: 0 -72px;
}
#main-menu nav .menu .recepty .icon {
    background-position: 0 -108px;
}
#main-menu nav .menu .laska-a-sex .icon {
    background-position: 0 -144px;
}
#main-menu nav .menu .active.zhave-drby .icon,
#main-menu nav .menu .zhave-drby:hover .icon {
    background-position: -36px 0;
}
#main-menu nav .menu .active.trapasy .icon,
#main-menu nav .menu .trapasy:hover .icon {
    background-position: -36px -36px;
}
#main-menu nav .menu .active.musite-vedet .icon,
#main-menu nav .menu .musite-vedet:hover .icon {
    background-position: -36px -72px;
}
#main-menu nav .menu .active.recepty .icon,
#main-menu nav .menu .recepty:hover .icon {
    background-position: -36px -108px;
}
#main-menu nav .menu .active.laska-a-sex .icon,
#main-menu nav .menu .laska-a-sex:hover .icon {
    background-position: -36px -144px;
}
#main-menu nav .menu .krimi-pribehy .icon {
    background-position: 0 -183px;
}
#main-menu nav .menu .active.krimi-pribehy .icon,
#main-menu nav .menu .krimi-pribehy:hover .icon {
    background-position: -36px -183px;
}
#main-menu nav .menu .aha-prozeny .icon {
    background-position: 0 -338px;
    width: 60px;
    height: 50px;
}
#main-menu nav .menu .active.aha-prozeny .icon,
#main-menu nav .menu .aha-prozeny:hover .icon {
    background-position: -83px -338px;
}
#main-menu ul.menu {
    margin: 0;
    padding: 0;
}
#main-menu ul.menu li {
    padding: 0;
    margin: 0;
    float: left;
    width: 20%;
}
#main-menu ul.menu li a {
    display: block;
    position: relative;
    padding: 0;
    color: #5c6773;
    font:
        bold 10px/12px Arial,
        Helvetica;
    text-decoration: none;
    text-align: center;
}
#main-menu ul.menu li a:focus,
#main-menu ul.menu li a:hover,
#main-menu ul.menu li.active a {
    position: relative;
    color: #fff;
    background-color: #ff001e;
    background-position: 10px 13px;
}
#main-menu ul.menu li.active a {
    -webkit-box-shadow: inset 0 0 6px #555;
    box-shadow: inset 0 0 6px #555;
}
.breadcrumbs {
    padding: 10px 5px;
}
#breadcrumbs,
.breadcrumbs {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: bold;
    color: #707b87;
}
#breadcrumbs a,
.breadcrumbs a {
    color: inherit;
    text-decoration: none;
}
#breadcrumbs a:after,
.breadcrumbs a:after {
    content: "";
    display: inline-block;
    border-right: 1px solid #707b87;
    border-bottom: 1px solid #707b87;
    width: 5px;
    height: 5px;
    margin: 0 6px 1px 4px;
    transform: rotate(-45deg);
}
.breadcrumbs {
    overflow: hidden;
    padding-top: 5px;
    padding-left: 0;
}
.breadcrumbs li:last-child a:after {
    display: none;
}
.breadcrumbs li {
    float: left;
}
#breadcrumbs a:hover,
.breadcrumbs a:hover {
    text-decoration: underline;
}
.breadcrumbs__arrow {
    display: inline-block;
    border-right: 1px solid #707b87;
    border-bottom: 1px solid #707b87;
    width: 5px;
    height: 5px;
    margin: 0 6px 1px 4px;
    transform: rotate(-45deg);
}
.breadcrumbs ol {
    margin: 0;
    padding: 0;
}
.articleItemBig {
    z-index: 10;
    position: relative;
    margin-bottom: 15px;
    -webkit-box-shadow: 0 9px 16px -3px rgba(0, 0, 0, 0.5);
    box-shadow: 0 9px 16px -3px rgba(0, 0, 0, 0.5);
}
.articleItemBig h2 {
    line-height: 1.2;
    padding: 5px 10px;
    background: white;
    margin: 0;
    font-size: 2em;
}
.articleItem h3 a,
.articleItemBig h2 a,
.articleItemMed h3 a,
.h2-wiki-title a {
    display: block;
    text-decoration: none;
    color: #343f4b;
    text-shadow: 0 1px 0 #dee6ee;
}
.articleItem h3 a:active,
.articleItemBig h2 a:active,
.articleItemMed h3 a:active,
.h2-wiki-title a:active {
    text-decoration: underline;
    color: #343f4b;
}
.articleItem h3 a:visited,
.articleItemBig h2 a:visited,
.articleItemMed h3 a:visited,
.h2-wiki-title a:visited {
    color: #707b87;
}
.articleItemBig .articleImage img {
    width: 100%;
    max-width: 768px;
}
.articleItemBig .articleImage {
}
.articleItemMed {
}
.articleItemMed .imageWrapper {
}
.articleItemMed {
    float: left;
    width: 48%;
    margin-bottom: 15px;
    -webkit-box-shadow: 0 9px 16px -3px rgba(0, 0, 0, 0.3);
    box-shadow: 0 9px 16px -3px rgba(0, 0, 0, 0.3);
    margin-left: 2%;
}
.articleItemMed.first {
    margin-left: 0;
    margin-right: 2%;
}
.articleItemMed .imageWrapper img {
    width: 100%;
    max-width: 384px;
}
.articleItemMed h3 {
    padding: 10px 5px;
    background: white;
    font:
        bold 1em/1.25 Arial,
        Helvetica;
}
.articleItemMed h3 a {
    display: block;
    height: 6em;
    overflow: hidden;
    text-decoration: none;
}
.articleItemMed header {
    position: absolute;
    z-index: 1;
}
.articleItemMed header a {
    text-decoration: none;
}
.articleItemMed a .articleMeta.promoted {
    position: relative;
    left: -10px;
    color: #fff;
    background-color: #ff001e;
}
.articleItemMed a .articleMeta.promoted .ribbon {
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -10px;
    left: 0;
    background: transparent url("../../../images/ahacz/ribbon-left-red.png") no-repeat 0 0;
}
.articleItemMed a .articleMeta.promoted strong {
    font: bold 10px/2 Arial;
    text-transform: uppercase;
    padding: 0 10px;
}
#main-content .articleItemMed a:focus .articleMeta.promoted,
.articleItemMed a:hover .articleMeta.promoted {
    text-decoration: underline;
    color: #fff;
}
.articleItem {
    display: table;
    width: 100%;
    clear: both;
    padding: 2px 0;
    border-top: 1px #cad5df solid;
    border-bottom: 1px #fff solid;
    position: relative;
}
.articleItemShadow {
    box-shadow:
        0 1px 0 #cad5df,
        0 -1px 0 #fff;
}
.articleItem.first {
    border-top: none;
}
.articleItem.last {
    border-bottom: none;
}
.articleItem > div {
    display: table-row;
}
.articleItem .imageWrapper {
    display: table-cell;
    height: 70px;
    width: 110px;
    padding: 2px 10px 2px 0;
}
.articleItem .articleMetaAd {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    height: 16px;
    line-height: 16px;
    width: 110px;
    color: #343f4b;
    font-weight: 700;
    font-size: 12px;
    padding-left: 5px;
    box-sizing: border-box;
}
.articleItem .articleContent {
    display: table-cell;
    vertical-align: middle;
    height: 70px;
}
.articleItem h3,
.h2-wiki-title {
    font-size: 0.875em;
    line-height: 1.25;
    margin: 0;
}
.articleItem h3 a,
.h2-wiki-title a {
    text-decoration: none;
}
#main-content {
}
#main-content .articles h2,
#main-content .asideArticles h3,
#main-content .sectionHeading {
    width: 100%;
    margin: 0;
    position: relative;
    font:
        bold 1.25em/2em "Helvetica Neue",
        Helvetica,
        Arial;
    font-stretch: condensed;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #fff;
    -webkit-box-shadow: 0 2px 4px #aaa;
    box-shadow: 0 2px 4px #aaa;
    margin: 15px 0 5px;
}
#main-content .articles h2 a,
#main-content .articles h2 div,
#main-content .asideArticles h3 a,
#main-content .asideArticles h3 div,
#main-content .sectionHeading a,
#main-content .sectionHeading div {
    display: block;
    height: 2em;
    padding-left: 5px;
    color: #fff;
    text-decoration: none;
    background: #ff001e url("../../../images/ahacz/next-white.png") no-repeat 96% 50%;
}
#main-content .articles h2 a:focus,
#main-content .articles h2 a:hover,
#main-content .asideArticles h3 a:focus,
#main-content .asideArticles h3 a:hover,
#main-content .sectionHeading a:focus,
#main-content .sectionHeading a:hover {
    background-color: #cc0018;
}
#main-content .dark .articles h2 a,
#main-content .dark .asideArticles h3 a,
#main-content .dark .sectionHeading a,
#main-content .tmavy .articles h2 a,
#main-content .tmavy .asideArticles h3 a,
#main-content .tmavy .sectionHeading a {
    background-color: #343f4b;
}
#main-content .dark .articles h2 a:focus,
#main-content .dark .articles h2 a:hover,
#main-content .dark .asideArticles h3 a:focus,
#main-content .dark .asideArticles h3 a:hover,
#main-content .dark .sectionHeading a:focus,
#main-content .dark .sectionHeading a:hover,
#main-content .tmavy .articles h2 a:focus,
#main-content .tmavy .articles h2 a:hover,
#main-content .tmavy .asideArticles h3 a:focus,
#main-content .tmavy .asideArticles h3 a:hover,
#main-content .tmavy .sectionHeading a:focus,
#main-content .tmavy .sectionHeading a:hover {
    background-color: #48535f;
}
#main-menu + .sectionHeading,
p #main-content .sectionHeading.clean {
    margin: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
#main-content .sectionHeading div,
#main-content .sectionHeading.clean a,
#main-menu + .sectionHeading a,
#main-menu + .sectionHeading div {
    background-image: none;
}
#main-content .articleItem .promoted.label {
    position: absolute;
    left: 0;
    top: 4px;
    display: block;
    height: 30px;
}
#main-content .articleItem .promoted.label a {
    position: relative;
    display: block;
    height: 18px;
    padding: 0 10px 0 5px;
    color: #fff;
    font:
        bold 10px/20px Arial,
        Helvetica;
    text-transform: uppercase;
    text-decoration: none;
    background: transparent url("../../../images/ahacz/mobile/label-red.png") no-repeat right top;
    z-index: 1;
}
#main-content .articleItem .promoted.label .ribbon {
    display: none;
}
#main-content a.load {
    position: relative;
    width: 150px;
    height: 28px;
}
#main-content a.load span {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    margin: 9px;
    background: url("../../../images/ahacz/mobile/prev-white.png") no-repeat;
}
#main-content a.load.next span {
    left: auto;
    right: 0;
    top: 0;
    background-image: url("../../../images/ahacz/mobile/next-white.png");
}
#main-content .loadMore a.load {
    width: 190px;
}
#article header {
    padding: 10px 5px;
    border-bottom: 1px #dee6ee solid;
    margin-bottom: 0.5em;
}
#article header .datetime {
    float: right;
    color: #b6c1cc;
    font:
        bold italic 1em/1 Arial,
        Helvetica;
}
#article header .categoryTitle {
    display: block;
    font:
        bold 1em/1 Arial,
        Helvetica;
    text-transform: uppercase;
    letter-spacing: 0.15ex;
    text-decoration: none;
}
#article > h1 {
    line-height: 1.2;
    font-size: 2em;
    padding: 0 5px;
    margin-bottom: 0.5em;
}
#article > h1 a {
    color: inherit;
    text-decoration: none;
}
#article #topImg img {
    width: 100%;
}
#article .galleryArticle {
    background: white;
    padding: 5px;
    -webkit-box-shadow: 0 6px 17px -6px rgba(0, 0, 0, 0.25);
    box-shadow: 0 6px 17px -6px rgba(0, 0, 0, 0.25);
}
#article .galleryArticle div.galleryCount {
    float: left;
    width: 25%;
    font-size: 12px;
}
#article .galleryArticle .item {
    width: 25%;
    float: right;
}
#article .galleryArticle .item figure {
    padding-left: 5px;
}
#article .articleContent {
    padding: 0 5px;
    line-height: 1.5;
    margin: 1em 0;
}
#article .fb {
    float: left;
    margin-right: 1em;
}
#article .perex {
    font-weight: bold;
    line-height: 1.25;
}
#article .articleContent p {
    margin: 1em 0;
    line-height: 1.5;
}
#article embed,
#article iframe,
#article img {
    max-width: 100%;
}
#article .artImg {
    width: auto !important;
}
.articleContent a.arrow {
    display: block;
    padding-right: 20px;
    background: transparent url("../../../images/ahacz/mobile/arrow-right.png") no-repeat 99% 50%;
}
.articleContent a.arrow span {
    float: right;
    font-size: 2em;
}
#article .author {
    text-align: right;
    color: #98a3af;
    font-style: italic;
}
#article .artAuthors strong {
    font-style: normal;
}
#article footer {
    margin-top: 1em;
}
#article .article-keywords {
    border-top: 1px solid #ccc;
    padding: 10px 0;
    font-size: 11px;
}
#article .article-keywords a {
    color: #343f4b;
    font-size: 14px;
    text-decoration: none;
    margin-right: 15px;
}
#article .article-keywords a:hover {
    color: #dc0032;
    text-decoration: underline;
}
#mainFooter .links p {
    text-align: center;
    margin: 15px;
}
#mainFooter a.button {
    width: 75%;
    min-width: 220px;
}
#mainFooter section h3 {
    width: 100%;
    margin: 0;
    position: relative;
    font:
        bold 1.25em/2 Arial,
        Helvetica;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #fff;
    -webkit-box-shadow: 0 2px 4px #aaa;
    box-shadow: 0 2px 4px #aaa;
    margin: 15px 0 5px;
    background: #343f4b;
}
#mainFooter section h3 span {
    padding-left: 5px;
}
#mainFooter .contacts p,
#mainFooter .contacts ul {
    font-size: 0.75em;
    line-height: 1.2;
}
#mainFooter .contacts ul {
    padding: 0 10px;
    float: left;
    list-style-type: none;
}
#mainFooter .contacts p {
    clear: both;
    margin: 1em 2%;
}
#article.gallery {
    padding: 10px;
}
#article.gallery > h1 {
    margin: 0 0 10px;
    padding: 0;
    color: white;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
    background: transparent;
}
#article.gallery section {
}
#article.gallery .galleryItem {
    float: left;
    width: 33.3333%;
    margin: 0;
}
#article.gallery .galleryItem.last {
}
#article.gallery .galleryItem a {
    margin-right: 0;
    display: block;
}
#article.gallery .galleryItem.last a {
    margin-right: 0;
}
#article.gallery .galleryItem a img {
    width: 100%;
    -webkit-box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.65);
    box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.65);
}
#article.gallery .galleryItem a img {
    -webkit-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
    transition: opacity 1s ease-in;
}
#article.gallery .galleryItem a img.showed {
    opacity: 1;
}
#article .singlePhotoDescription {
    font-size: 0.85em;
    background: white;
    padding: 0 5px;
    -webkit-box-shadow: 0 6px 17px -6px rgba(0, 0, 0, 0.25);
    box-shadow: 0 6px 17px -6px rgba(0, 0, 0, 0.25);
}
#article .singlePhotoDescription span {
    color: #848f9b;
    font-style: italic;
}
#article.gallery .photoFull {
    width: 100%;
}
#article.gallery .photoFull .photoWrapper {
    text-align: center;
}
#article.gallery .photoFull img {
    display: block;
    position: relative;
    margin: auto;
    -webkit-box-shadow: 0 4px 11px -1px rgba(0, 0, 0, 0.85);
    box-shadow: 0 4px 11px -1px rgba(0, 0, 0, 0.85);
    max-height: 100%;
}
#article.gallery .photoFull .photoDescription {
    text-align: center;
    color: #dee6ee;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
    margin-top: 0.5em;
}
#article.gallery .photoFull .photoDescription span {
    color: #98a3af;
    font-style: italic;
}
#article.gallery .controls {
    position: absolute;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
}
#article.gallery .controls > div {
    display: block;
    float: left;
    width: 35%;
    height: 60px;
}
#article.gallery .controls > div.back {
    width: 30%;
}
#article.gallery .controls a {
    display: block;
    height: 60px;
    line-height: 60px;
    font-size: 12px;
    background-color: #48535f;
    background-color: rgba(72, 83, 95, 0.96);
    color: #fff;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}
#article.gallery .controls .prev a {
    text-align: left;
    padding-left: 5px;
    border-right: 1px #5c6773 solid;
}
#article.gallery .controls .next a {
    text-align: right;
    padding-right: 5px;
    border-left: 1px #5c6773 solid;
}
#article.gallery .controls .back a {
    border-left: 1px #343f4b solid;
    border-right: 1px #343f4b solid;
}
#article.gallery [class^="ico"] small {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent url("../../../images/ahacz/mobile/gallery.png") no-repeat left top;
}
#article.gallery .controls [class^="ico"] small {
    position: relative;
    top: 6px;
}
#article.gallery .ico-close small {
    background-position: 0 0;
}
#article.gallery .ico-back small {
    background-position: 0 -20px;
}
#article.gallery .ico-next small {
    background-position: 0 -40px;
}
#article.gallery .ico-prev small {
    background-position: 0 -60px;
}
#article.gallery .ico-nextround small {
    background-position: 0 -80px;
}
#article.gallery .ico-prevround small {
    background-position: 0 -100px;
}
#article.gallery .backToArticle {
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    position: absolute;
    top: 0;
    right: 0;
    font: 12px/40px Arial;
    z-index: 901;
}
.forumBox {
    margin: 10px 0;
}
.forumBox section {
    border-top: 1px #cad5df solid;
    border-bottom: 1px #fff solid;
    clear: both;
    padding: 0.5em 10px;
}
.forumBox header strong {
    font-size: 0.875em;
    float: left;
}
.forumBox header em {
    font-size: 0.75em;
    float: right;
    color: #848f9b;
}
.forumBox .message {
    clear: both;
    word-break: break-word;
}
.forumBox .bottom {
    margin: 20px 0 0;
}
.forumBox .bottom .button {
    padding-left: 1em;
    padding-right: 1em;
}
.forumHeader h1 {
    line-height: 1.2;
    padding: 5px 10px;
    background: white;
    margin: 0;
    font-size: 2em;
}
.forum .pagination {
    margin: 20px 0;
}
.forum .pages {
    text-align: center;
}
.forum .button.nextPage,
.forum .button.prevPage {
    width: 30px;
}
.forum .button.page {
    padding-left: 0;
    padding-right: 0;
}
.forum .info .nickname {
    font-size: 0.75em;
    float: left;
}
.forum .info .date {
    font-size: 0.75em;
    float: right;
    color: #848f9b;
}
.forum .text {
    clear: both;
    font-size: 1.125em;
    margin: 1em 0;
}
.forum .commentsBox,
.forum .pagination {
    clear: both;
}
.forum .pagination {
    margin-top: 20px;
}
.forum .myComments {
    margin-left: 0;
}
.forum .forumTop {
    padding-top: 20px;
    padding-bottom: 20px;
}
.forum .forumTop > div,
.forum .forumTop > header {
    padding: 0 10px;
}
.forum h2,
.forum header {
    margin-bottom: 10px;
}
.forum .message.last {
    border-bottom: none;
}
.forum .message.deleted {
    color: #98a3af;
}
.forum .buttons a.btnLeft {
    margin-right: 10px;
}
.forum .buttons a.btnRight {
    margin-left: 10px;
}
.forum .forumBoxInner a.red {
    color: #ff001e;
}
.forum .forumBoxInner a.red:focus,
.forum .forumBoxInner a.red:hover {
    color: #dc0032;
}
.forum .headerGray {
    margin-left: -10px;
    padding-bottom: 10px;
    background: url("../../../images/ahacz/ribbon-left-midgrey.png") no-repeat scroll left bottom transparent;
    position: relative;
}
.forum .headerGray div {
    background-color: #cad5df;
    display: block;
    color: #707b87;
    line-height: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -1px;
    padding-left: 20px;
}
.forum header h1 {
    line-height: 40px;
}
.forum header strong.threadDesc {
    font-weight: bold;
    font-size: 14px;
}
.forum .loggedBox .banned {
    background-color: #cc0018;
    color: white;
    padding: 5px 10px;
}
.forum .loggedBox,
.forum .loggedBox ul,
.forum .loginBox,
.forum .loginBox ul {
    margin-left: -10px;
    padding-left: 10px;
    margin-bottom: 10px;
}
.forum .loggedBox,
.forum .loginBox {
    padding-top: 10px;
    border-top: 1px solid #cad5df;
}
.forum .loggedBox ul,
.forum .loginBox ul {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #cad5df;
    padding-top: 5px;
}
.forum .loggedBox li,
.forum .loginBox li {
    display: inline-block;
    list-style-type: none;
    padding-right: 10px;
}
.forum .loggedBox li span,
.forum .loginBox li span {
    display: inline-block;
    float: left;
    background: url("../../../images/ahacz/elements.png?v=12") -210px -48px no-repeat transparent;
    width: 12px;
    height: 12px;
}
.forum .loggedBox li a,
.forum .loginBox li a {
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase;
    display: inline-block;
}
.forum .loginForm .input,
.forum .loginForm div.terms,
.forum .loginForm input.submit {
    margin-bottom: 10px;
}
.forum .loginForm .input input,
.forum .loginForm .input label {
    display: block;
    float: left;
}
.forum .loginForm .input label {
    padding-right: 10px;
    text-align: right;
    width: 30%;
}
.forum .loginForm .input input {
    width: 60%;
}
.forum .button,
.forum input.submit {
    padding-left: 1em;
    padding-right: 1em;
}
.forum .loginForm div.terms,
.forum .loginForm input.submit {
    margin-left: 100px;
}
.forum .replyForm .smileys a {
    background: none repeat scroll 0 0 #ffffff;
    border: 1px solid #cad5df;
    border-radius: 1px 1px 1px 1px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset;
}
.forum .replyForm .smileys img {
    display: block;
}
.forum .replyForm input.subject,
.forum .replyForm textarea {
    display: block;
    width: 90%;
    margin-bottom: 10px;
}
.forum .replyForm textarea {
    min-height: 100px;
    resize: vertical;
}
.forum .replyForm .remaining {
    float: right;
    font-size: 12px;
    line-height: 24px;
    color: #707b87;
}
.forum .replyForm .smileys a {
    display: block;
    float: left;
    text-decoration: none;
    margin-bottom: 2px;
    background: white;
    margin-right: 2px;
    padding: 2px;
}
.forum .replyForm .notify {
    line-height: 30px;
    font-size: 14px;
    float: right;
}
.forum .replyForm .notify label {
    line-height: inherit;
}
.forum .replyForm .notify input {
    vertical-align: middle;
}
.forum #mainLogin,
.forum #mainReact {
    display: none;
    padding-bottom: 10px;
    font-size: 12px;
}
.forum .commentsBox {
    margin-top: 20px;
}
.forum .commentsBox h4 {
    margin-top: 0;
}
.forum .commentsBox .message {
    border-top: 1px #cad5df solid;
    padding: 0.5em 10px;
}
.forum #badwordsCont {
    display: none;
}
.forum .commentsBox .message p {
    margin-bottom: 0;
    line-height: 1.25;
}
.forum .commentsBox .level0 {
    margin-left: 0;
}
.forum .commentsBox .level1,
.forum .commentsBox .replyLevel1 {
    margin-left: 30px;
}
.forum .commentsBox .level2,
.forum .commentsBox .replyLevel2 {
    margin-left: 60px;
}
.forum .commentsBox .level3,
.forum .commentsBox .replyLevel3,
.forum .commentsBox .replyLevel4 {
    margin-left: 90px;
}
.forum .commentsBox .replyForm textarea {
    min-height: 100px;
}
.forum .commentsBox .replyLevel1 textarea {
    width: 99%;
}
.forum .commentsBox .replyLevel2 textarea {
    width: 99%;
}
.forum .commentsBox .replyLevel3 textarea,
.forum .commentsBox .replyLevel4 textarea {
    width: 99%;
}
.forum .commentsBox .loginForm form,
.forum .commentsBox .replyForm form {
    padding: 10px 0;
}
.forum .commentsBox .loginForm form {
}
.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
}
.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
}
.hidden {
    display: none !important;
    visibility: hidden;
}
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
.invisible {
    visibility: hidden;
}
.clearfix:after,
.clearfix:before {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    clear: both;
}
.clear {
    clear: both;
}
.floatLeft {
    float: left;
}
.floatRight {
    float: right;
}
.alignLeft {
    float: left;
}
.alignRight {
    text-align: right;
}
.centerText {
    text-align: center;
}
.centerBlock {
    margin-left: auto;
    margin-right: auto;
}
.imageWrapper {
    overflow: hidden;
    position: relative;
    display: block;
}
.img,
.positionRelative {
    position: relative;
}
.borderTop {
    border-top: 5px #ff001e solid;
    padding-top: 10px;
}
.borderBottom {
    border-bottom: 5px #ff001e solid;
    padding-bottom: 10px;
}
.readMore {
    display: inline-block;
    text-transform: uppercase;
    color: #98a3af;
    text-decoration: none;
}
.readMore:hover {
    text-decoration: underline;
}
.readMore span {
    background: url("../../../images/ahacz/elements.png?v=12") -210px -48px no-repeat transparent;
    display: block;
    float: left;
    width: 12px;
    height: 12px;
    margin-top: 1px;
    margin-left: -4px;
    margin-right: 1px;
}
.marginTop {
    margin-top: 30px;
}
.marginBottom {
    margin-bottom: 30px;
}
.marginTop20 {
    margin-top: 20px;
}
.marginBottom20 {
    margin-bottom: 20px;
}
.marginTop10 {
    margin-top: 10px;
}
.marginBottom10 {
    margin-bottom: 10px;
}
.marginTop15 {
    margin-top: 15px;
}
.icon {
    display: block;
}
.red {
    color: #ff001e;
}
.fixed {
    position: fixed;
    top: 0;
}
@font-face {
    font-family: "Listicons";
    src: url("//img2.cncenter.cz/fonts/icons/list-icons/listicons-webfont.eot");
    src:
        url("//img2.cncenter.cz/fonts/icons/list-icons/listicons-webfont.eot?#iefix") format("embedded-opentype"),
        url("//img2.cncenter.cz/fonts/icons/list-icons/listicons-webfont.woff") format("woff"),
        url("//img2.cncenter.cz/fonts/icons/list-icons/listicons-webfont.ttf") format("truetype"),
        url("//img2.cncenter.cz/fonts/icons/list-icons/listicons-webfont.svg#ListiconsGlyphsRegular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.fontface #article.gallery [class^="ico"] {
    display: none;
}
.fontface #article.gallery .fontIcon {
    font-size: 30px;
    line-height: 60px;
}
.fontface #article.gallery .fontIcon:before {
    font-family: "Listicons";
    margin: 0 10px;
    display: block;
    height: 100%;
    text-transform: none;
}
.fontface #article.gallery .controls .fontIcon:before {
    font: 40px/60px "Listicons";
    display: block;
}
.fontface #article.gallery .controls .back .fontIcon:before {
    font-size: 50px;
    position: relative;
    left: 15px;
}
#article.gallery .backToArticle .fontIcon:before {
    float: right;
    font-size: 30px;
    line-height: 40px;
}
.fontface .fontIcon.reversed:before {
    float: left;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.fontface .fontIcon.round-1:before {
    content: "A";
}
.fontface .fontIcon.round-2:before {
    content: "B";
}
.fontface .fontIcon.round-3:before {
    content: "C";
}
.fontface .fontIcon.round-4:before {
    content: "D";
}
.fontface .fontIcon.round-5:before {
    content: "E";
}
.fontface .fontIcon.round-6:before {
    content: "F";
}
.fontface .fontIcon.round-7:before {
    content: "G";
}
.fontface .fontIcon.round-8:before {
    content: "H";
}
.fontface .fontIcon.round-9:before {
    content: "I";
}
.fontface .fontIcon.round-10:before {
    content: "J";
}
.fontface .fontIcon.round-11:before {
    content: "K";
}
.fontface .fontIcon.round-12:before {
    content: "L";
}
.fontface .fontIcon.hierarchical-1:before {
    content: "M";
}
.fontface .fontIcon.hierarchical-2:before {
    content: "N";
}
.fontface .fontIcon.hierarchical-3:before {
    content: "O";
}
.fontface .fontIcon.hierarchical-4:before {
    content: "P";
}
.fontface .fontIcon.arrow-1:before {
    content: "Q";
}
.fontface .fontIcon.arrow-2:before {
    content: "R";
}
.fontface .fontIcon.arrow-3:before {
    content: "S";
}
.fontface .fontIcon.arrow-4:before {
    content: "T";
}
.fontface .fontIcon.arrow-5:before {
    content: "U";
}
.fontface .fontIcon.arrow-6:before {
    content: "V";
}
.fontface .fontIcon.arrow-7:before {
    content: "W";
}
.fontface .fontIcon.arrow-8:before {
    content: "X";
}
.fontface .fontIcon.arrow-9:before {
    content: "Y";
}
.fontface .fontIcon.arrow-10:before {
    content: "Z";
}
.fontface .fontIcon.arrow-11:before {
    content: "a";
}
.fontface .fontIcon.arrow-12:before {
    content: "b";
}
.fontface .fontIcon.arrow-13:before {
    content: "c";
}
.fontface .fontIcon.arrow-14:before {
    content: "d";
}
.fontface .fontIcon.arrow-15:before {
    content: "e";
}
.fontface .fontIcon.arrow-16:before {
    content: "f";
}
.fontface .fontIcon.arrow-17:before {
    content: "g";
}
.fontface .fontIcon.arrow-18:before {
    content: "h";
}
.fontface .fontIcon.arrow-19:before {
    content: "i";
}
.fontface .fontIcon.arrow-20:before {
    content: "j";
}
.fontface .fontIcon.arrow-21:before {
    content: "k";
}
.fontface .fontIcon.arrow-22:before {
    content: "l";
}
.fontface .fontIcon.bullet-1:before {
    content: "m";
}
.fontface .fontIcon.bullet-2:before {
    content: "n";
}
.fontface .fontIcon.bullet-3:before {
    content: "o";
}
.fontface .fontIcon.bullet-4:before {
    content: "p";
}
.fontface .fontIcon.bullet-5:before {
    content: "q";
}
.fontface .fontIcon.misc-1:before {
    content: "r";
}
.fontface .fontIcon.misc-2:before {
    content: "s";
}
.fontface .fontIcon.misc-3:before {
    content: "t";
}
.fontface .fontIcon.misc-4:before {
    content: "u";
}
.fontface .fontIcon.misc-5:before {
    content: "v";
}
.fontface .fontIcon.misc-6:before {
    content: "w";
}
.fontface .fontIcon.misc-7:before {
    content: "x";
}
.fontface .fontIcon.misc-8:before {
    content: "y";
}
.fontface .fontIcon.misc-9:before {
    content: "z";
}
@media screen and (orientation: landscape) and (min-width: 400px) {
    body {
        padding-left: 80px;
    }
    body.clean {
        padding-left: 0;
    }
    #main-menu {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 80px;
        -webkit-box-shadow: 1px 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: 1px 0 6px rgba(0, 0, 0, 0.3);
    }
    #main-menu ul.menu {
        height: 100%;
    }
    #main-menu ul.menu li {
        float: none;
        height: 20%;
        width: auto;
    }
    #article.gallery .controls {
        position: static;
        width: 100%;
        height: 100%;
    }
    #article.gallery .controls > div {
        display: block;
        position: absolute;
        width: 25%;
        height: 100%;
    }
    #article.gallery .controls > div.back {
        width: 30%;
    }
    #article.gallery .controls a {
        display: block;
        height: 100%;
        line-height: 60px;
        font-size: 12px;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.4);
    }
    #article.gallery .controls .prev {
        text-align: left;
        padding-left: 0;
        position: absolute;
        top: 0;
        left: 0;
    }
    #article.gallery .controls .next {
        text-align: right;
        padding-right: 0;
        position: absolute;
        top: 0;
        right: 0;
    }
    #article.gallery .controls .prev a {
        padding-left: 0;
        border-right: none;
        width: 100%;
        height: 100%;
        position: relative;
    }
    #article.gallery .controls .next a {
        padding-right: 0;
        border-left: none;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .fontface #article.gallery .controls .fontIcon:before {
        font: 80px/1 "Listicons";
        display: block;
    }
    #article.gallery .controls .prev .fontIcon {
        position: absolute;
        top: 40%;
        left: -10%;
    }
    #article.gallery .controls .next .fontIcon {
        position: absolute;
        top: 40%;
        right: -10%;
    }
    #article.gallery .controls > div.back {
        border: none;
        position: absolute;
        top: 0;
        right: 150px;
        height: 40px;
        color: white;
        font: 12px/40px Arial;
        z-index: 901;
        width: 200px;
    }
    #article.gallery .controls .back a {
        background: transparent;
        text-transform: uppercase;
        border: none;
        color: white;
    }
    #article.gallery .controls .back .ico-back {
        display: block;
        line-height: 40px;
        background-image: none;
        text-shadow: none;
        text-align: right;
    }
    #article.gallery .controls .back .ico-back small {
        display: none;
    }
    #article.gallery .controls .back .fontIcon:before {
        display: block;
        float: right;
        background: transparent;
        text-transform: none;
        text-shadow: none;
        border: none;
        color: white;
        font-size: 30px;
        line-height: 40px;
    }
}
@media screen and (orientation: landscape) and (min-width: 640px) {
    #article.gallery .controls .prev .fontIcon {
        left: 15%;
    }
    #article.gallery .controls .next .fontIcon {
        right: 15%;
    }
    #article .galleryArticle div.galleryCount {
        font-size: 16px;
    }
}
@media (min-height: 400px) {
    #main-menu ul.menu li a {
        padding: 10px 0;
    }
}
@media (orientation: landscape) and (min-width: 640px) {
    #article.gallery .galleryItem {
        width: 20%;
    }
}
@media screen and (min-width: 480px) {
    .articleItemMed h3 a {
        height: 5em;
    }
}
@media screen and (max-width: 479px) {
    .articleItemMed h3 a {
        height: 6em;
    }
}
@media screen and (max-width: 400px) {
    .articleItemMed h3 a {
        height: 7.5em;
    }
}
@media screen and (max-width: 300px) {
    .articleItemMed h3 a {
        height: 16em;
    }
}
@media screen and (max-height: 320px) {
}
.zaha-banner-cont {
    position: relative;
    width: 100%;
    height: 60px;
    margin: 15px 0;
    background-color: #9b59b6;
}
.zaha-banner-arrow {
    position: absolute;
    right: 5px;
    top: 15px;
    height: 30px;
    width: 20px;
    background: url("../../../images/ahacz/mobile/banner-sprite.png") no-repeat 0 0;
}
.zaha-banner-circle {
    position: absolute;
    left: 20px;
    top: -8px;
    height: 80px;
    width: 80px;
    background: url("../../../images/ahacz/mobile/banner-sprite.png") no-repeat 0 -120px;
}
.zaha-banner-logo {
    position: relative;
    height: 60px;
    width: 235px;
    margin: 0 auto;
    background: url("../../../images/ahacz/mobile/banner-sprite.png") no-repeat 0 -42px;
}
@media screen and (max-width: 480px) {
    .zaha-banner-cont {
        position: relative;
        width: 100%;
        height: 40px;
        margin: 15px 0;
        background-color: #9b59b6;
    }
    .zaha-banner-arrow {
        position: absolute;
        right: 5px;
        top: 10px;
        height: 30px;
        width: 15px;
        background: url("../../../images/ahacz/mobile/banner-sprite.png") no-repeat -246px 0;
    }
    .zaha-banner-circle {
        position: absolute;
        left: 5px;
        top: -8px;
        height: 53px;
        width: 53px;
        background: url("../../../images/ahacz/mobile/banner-sprite.png") no-repeat -246px -120px;
    }
    .zaha-banner-logo {
        position: relative;
        height: 40px;
        width: 178px;
        margin: 0 auto;
        background: url("../../../images/ahacz/mobile/banner-sprite.png") no-repeat -235px -54px;
    }
}
#main-menu {
    height: 68px;
    overflow: hidden;
}
#main-menu .arrowLeft {
    position: absolute;
    width: 20px;
    height: 68px;
    background: white;
    left: 0;
    top: 0;
    z-index: 2;
}
#main-menu .arrowLeft .arrowIcon {
    position: absolute;
    left: 2px;
    top: 28px;
    width: 13px;
    height: 13px;
    background: url("../../../images/ahacz/elements.png?v=12") no-repeat;
    background-position: -196px -0px;
}
#main-menu .arrowRight {
    position: absolute;
    width: 20px;
    height: 68px;
    background: white;
    right: 0;
    top: 0;
    z-index: 2;
}
#main-menu .arrowRight .arrowIcon {
    position: absolute;
    left: 2px;
    top: 28px;
    width: 13px;
    height: 13px;
    background: url("../../../images/ahacz/elements.png?v=12") no-repeat;
    background-position: -210px 0;
}
#main-menu nav .navContainer {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: -15px;
    top: 0;
    overflow: auto;
    overflow-x: auto;
    overflow-y: hidden;
}
#main-menu nav .navContainer #menu-drag {
    position: absolute;
    z-index: 1;
}
#main-menu ul.menu li {
    width: 80px;
}
@media screen and (orientation: landscape) and (min-width: 400px) {
    #main-menu {
        height: 100%;
    }
    #main-menu .arrowLeft {
        position: absolute;
        width: 80px;
        height: 20px;
        top: 0;
    }
    #main-menu .arrowLeft .arrowIcon {
        position: absolute;
        left: 32px;
        top: 2px;
        width: 13px;
        height: 15px;
        background: url("../../../images/ahacz/elements.png?v=12") no-repeat;
        background-position: -222px 0;
    }
    #main-menu .arrowRight {
        position: absolute;
        width: 80px;
        height: 20px;
        bottom: 0;
        top: auto;
    }
    #main-menu .arrowRight .arrowIcon {
        position: absolute;
        left: 32px;
        top: 2px;
        width: 13px;
        height: 15px;
        background: url("../../../images/ahacz/elements.png?v=12") no-repeat;
        background-position: -233px 0;
    }
    #main-menu nav .navContainer {
        position: absolute;
        top: 20px;
        bottom: 20px;
        left: -5px;
        right: -20px;
        height: auto;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }
    #main-menu nav .navContainer ul.menu li {
        width: 80px;
        height: 60px;
    }
    #main-menu nav .navContainer ul.menu li#cat-5139 {
        width: 80px;
        overflow: hidden;
    }
}
.fb-comments,
.fb-comments iframe[style],
.fb-comments span {
    width: 100% !important;
}
#main-content .enquiry {
    position: relative;
    background: white;
}
#main-content .enquiry .enquiryInner {
    background-color: white;
    position: relative;
    margin: 0 10px 10px;
}
#main-content .enquiry h2 {
    background: url("../../../images/ahacz/ribbon-left-grey.png") no-repeat scroll left bottom transparent;
}
#main-content .enquiry h2 span {
    display: block;
    background-color: #ff001e;
    text-transform: uppercase;
    color: white;
    font-size: 20px;
    font-family: "Helvetica Neue", Helvetica, Arial;
    font-weight: bold;
    letter-spacing: -1px;
    padding-left: 20px;
}
#main-content .enquiry .question strong {
    font-size: 24px;
    font-weight: bold;
    height: 100px;
}
#main-content .enquiry .question span {
    color: #707b87;
    font-size: 12px;
}
#main-content .enquiry ul {
    margin: 0;
    padding: 0;
}
#main-content .enquiry li {
    list-style-type: none;
    display: block;
    height: 40px;
}
#main-content .enquiry li .answer,
#main-content .enquiry li .value {
    line-height: 36px;
}
#main-content .enquiry li a {
    display: block;
    position: relative;
    font-size: 16px;
    color: #5c6773;
    text-decoration: none;
}
#main-content .enquiry.voted li .answer {
    cursor: default;
    float: left;
}
#main-content .enquiry li .value {
    display: block;
    float: right;
    font-weight: bold;
    padding-right: 10px;
}
#main-content .enquiry li .graph {
    display: block;
    clear: both;
    background-color: #cad5df;
    height: 5px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
#main-content .enquiry li .graphValue {
    display: block;
    position: absolute;
    background-color: #ff001e;
    height: 5px;
    left: 0;
    top: 0;
}
#main-content .enquiry li a .icon {
    height: 20px;
    width: 13px;
    background: transparent url("../../../images/ahacz/elements.png?v=12") no-repeat -210px 1px;
    position: relative;
    margin: 11px 0 0 5px;
    float: left;
}
#main-content .enquiry li a:focus .answer,
#main-content .enquiry li a:hover .answer {
    text-decoration: underline;
    color: #343f4b;
}
#main-content .enquiry li a:focus .value,
#main-content .enquiry li a:hover .value {
    color: #343f4b;
}
#main-content .enquiry li a:focus .graph,
#main-content .enquiry li a:hover .graph {
    background-color: #b6c1cc;
}
#main-content .enquiry li a:focus .graphValue,
#main-content .enquiry li a:hover .graphValue {
    background-color: #dc0032;
}
#main-content .enquiry.voted li a:hover .answer {
    text-decoration: none;
}
.csstransitions #main-content .enquiry li a .answer {
    text-decoration: none;
}
.csstransitions #main-content .enquiry li a .graph,
.csstransitions #main-content .enquiry li a .graphValue {
    -moz-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    -webkit-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}
.csstransitions #main-content .enquiry li a:focus .graph,
.csstransitions #main-content .enquiry li a:hover .graph {
    height: 8px;
}
.csstransitions #main-content .enquiry li a:focus .graphValue,
.csstransitions #main-content .enquiry li a:hover .graphValue {
    height: 8px;
}
#main-content .enquiry .countVotes {
    color: #707b87;
    font-size: 12px;
    padding: 10px 0;
}
.galleryHP {
    background: #343f4b;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
}
.galleryHP .float-left {
    float: left;
    width: 100%;
}
.galleryHP .float-right {
    float: right;
    width: 100%;
}
.galleryHP .float-right .jcarousel-prev {
    display: none;
}
.galleryHP .float-right .jcarousel-next {
    position: absolute;
    right: 0;
    top: auto;
    bottom: 10px;
    height: 90px;
    background: #ff0000;
    text-indent: -9999px;
    cursor: pointer;
    width: 30px;
}
.galleryHP .float-right .jcarousel-next .arrow-next {
    background: url("../../../images/ahacz/elements.png?v=12") -210px -12px no-repeat transparent;
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 46%;
    left: 33%;
}
.galleryHP .title {
    color: #ff001e;
    margin: 5px 10px;
    font-size: 16px;
    line-height: 20px;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
}
.galleryHP a.galleryLink {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    display: block;
    float: left;
    text-decoration: none;
    margin: 5px 10px;
    font-weight: bold;
}
.galleryHP a.galleryLink:hover {
    text-decoration: underline;
}
.galleryHP .jcarousel-clip {
    height: 90px;
    margin: 0;
}
.galleryHP .jcarousel-list {
    height: 90px;
    overflow: hidden;
}
.galleryHP .jcarousel-list li {
    margin: 0 10px;
    float: left;
}
.galleryHP .jcarousel-list li img {
    width: 140px;
    height: 90px;
}
.jcarousel {
    position: relative;
    overflow: hidden;
}
.jcarousel ul {
    width: 20000em;
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}
.jcarousel li {
    float: left;
}
.cookie-bar {
    position: fixed;
    display: none;
    text-align: center;
    padding: 10px 0;
    font-family: "opensans-regular";
    color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(50, 50, 50, 0.95);
    width: 100%;
    z-index: 200000;
}
.cookie-bar a {
    color: inherit;
}
.cookie-bar__title {
    font-family: "opensans-bold";
    font-size: 22px;
    line-height: 26px;
}
.cookie-bar__title br {
    display: inline;
}
.cookie-bar__text {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 0 13px;
}
.cookie-bar__text br {
    display: none;
}
.cookie-bar__enable-btn {
    display: inline-block;
    margin: 0 10px 0 auto;
    font-size: 14px;
    line-height: 35px;
    height: 35px;
    width: 140px;
    text-transform: Uppercase;
    background-color: #ff0000;
    cursor: pointer;
    margin-bottom: 13px;
    text-decoration: none;
}
.cookie-bar__more-info {
    font-size: 11px;
    margin-bottom: 5px;
    line-height: 15px;
    display: inline-block;
    text-decoration: underline;
}
.cookie-bar__more-info:hover {
    text-decoration: none;
}
.cookie-bar__close-btn {
    display: inline-block;
    width: 14px;
    height: 14px;
    overflow: hidden;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.cookie-bar__close-btn::after,
.cookie-bar__close-btn::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
}
.cookie-bar__close-btn::before {
    transform: rotate(45deg);
}
.cookie-bar__close-btn::after {
    transform: rotate(-45deg);
}
.cookie-bar__close-btn--thick::after,
.cookie-bar__close-btn--thick::before {
    height: 3px;
    margin-top: -2px;
}
.box-special {
    background-color: #ffffff;
    border: 1px solid #cad5df;
    position: relative;
    padding-bottom: 10px;
}
.box-special__header {
    background-color: #ff0000;
    position: relative;
    z-index: 1;
    background-size: cover;
    color: #fff;
    padding: 10px;
    margin-bottom: 20px;
}
.box-special__header a {
    text-decoration: none;
}
.box-special__header a:focus,
.box-special__header a:hover {
    text-decoration: underline;
}
.box-special__header-left {
    float: left;
    width: 50%;
}
.box-special__title {
    font-size: 22px;
    font-weight: bold;
    line-height: 20px;
}
.box-special__title a,
.box-special__title:focus,
.box-special__title:hover {
    color: #ffffff;
}
.box-special__subtitle {
    font-size: 13px;
    font-weight: bold;
    line-height: 15px;
    margin-top: 5px;
}
.box-special__header-right {
    float: left;
    padding-left: 10px;
    border-left: 1px solid #c51a1a;
    width: 50%;
    min-height: 55px;
    box-sizing: border-box;
}
.box-special__header-right a {
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    padding-right: 20px;
    display: block;
    position: relative;
}
.box-special__header-right a:after {
    border-top: solid 4px transparent;
    border-bottom: solid 4px transparent;
    border-left: solid 6px #ffffff;
    content: "";
    display: inline-block;
    position: absolute;
    top: 4px;
    right: 0;
}
.box-special__link {
    margin-bottom: 10px;
    text-align: center;
    display: block;
    font-size: 13px;
    text-decoration: underline;
    line-height: 16px;
    font-weight: 700;
}
.box-special__link:hover {
    text-decoration: none;
}
.box-special__link:after {
    border-top: solid 4px transparent;
    border-bottom: solid 4px transparent;
    border-left: solid 6px #ff0000;
    content: "";
    display: inline-block;
    margin-left: 5px;
}
.box-special--elections .box-special__header {
    background-color: #f4f7f9;
    color: #16212d;
}
.box-special--elections .box-special__subtitle a,
.box-special--elections .box-special__title a {
    color: #16212d;
}
.box-special--elections .box-special__link {
    color: #ff0000;
}
.box-special--elections .box-special__header-right {
    border-color: #cad5df;
}
.box-special--elections .box-special__header-right a {
    color: #16212d;
    text-decoration: underline;
}
.box-special--elections .box-special__header-right a:focus,
.box-special--elections .box-special__header-right a:hover {
    text-decoration: none;
}
.box-special--elections .box-special__header-right a:after {
    border-left-color: #ff0000;
}
.box-special--elections .list-article-horizontal-small .title-container h2 a {
    color: #16212d;
    text-decoration: none;
}
.box-special--elections .list-article-horizontal-small .title-container h2 a:focus,
.box-special--elections .list-article-horizontal-small .title-container h2 a:hover {
    color: #16212d;
    text-decoration: underline;
}
.box-special article.list-article-horizontal-small {
    position: relative;
    width: calc(100% - 30px);
    margin: 0 15px;
    padding: 15px 0;
    background: #ffffff;
    border-bottom: 1px solid #cad5df;
}
.box-special article.list-article-horizontal-small:after,
.box-special article.list-article-horizontal-small:before {
    content: "";
    display: table;
}
.box-special article.list-article-horizontal-small:after {
    clear: both;
}
.box-special article.list-article-horizontal-small:first-of-type {
    padding-top: 0;
}
.box-special article.list-article-horizontal-small:last-of-type {
    margin-bottom: 20px;
}
.box-special article.list-article-horizontal-small .image-container {
    width: 49%;
    height: auto;
    max-height: 180px;
    overflow: hidden;
    float: left;
    margin-right: 0;
}
.box-special article.list-article-horizontal-small .image-container img {
    width: 100%;
    height: auto;
}
.box-special article.list-article-horizontal-small .title-container {
    width: 46.5%;
    margin: 0;
    position: relative;
    float: right;
}
.box-special article.list-article-horizontal-small .title-container h2 {
    font-size: 13px;
    line-height: 1.3em;
    font-weight: bold;
    padding: 0;
    margin: 0;
}
.box-special--elections .box-special__title {
    font-size: 1.4em;
    line-height: 1em;
}
.box-special--elections .box-special__subtitle {
    font-size: 0.875em;
    line-height: 1.1em;
}
.box-special--elections .box-special__header-right a {
    font-size: 0.875em;
    line-height: 1.4em;
}
.box-special--elections article.list-article-horizontal-small .title-container {
    width: 56.5%;
}
.box-special--elections article.list-article-horizontal-small .title-container h2 {
    font-size: 0.875em;
    line-height: 1.25;
}
.box-special--elections article.list-article-horizontal-small .image-container {
    width: 40%;
}
.box-special--elections .box-special__link {
    font-size: 0.875em;
}
.box-special--elections .box-special__link:after {
    margin-left: 10px;
}
@media (min-width: 768px) {
    .box-special--elections .box-special__title {
        font-size: 1.4em;
        line-height: 1em;
    }
    .box-special--elections .box-special__header-right a {
        font-size: 1em;
        line-height: 1.4em;
    }
    .box-special--elections .box-special__header-right a:after {
        top: 10px;
    }
    .box-special--elections article.list-article-horizontal-small .image-container {
        width: 49%;
    }
    .box-special--elections article.list-article-horizontal-small .title-container {
        width: 47.5%;
    }
}
.boxadmin-list-article .title-basic.title-basic--red,
.boxadmin-list-article .title-basic.title-basic--red-left,
.boxadmin-list-article .title-basic.title-basic--red-right {
    position: relative;
    display: block;
    background-color: #ff001e;
    width: 100%;
    height: 40px;
    margin: 0 0 10px;
    padding: 0 0 0 10px;
    font:
        bold 20px/40px "Helvetica Neue",
        Helvetica,
        Arial;
    font-stretch: condensed;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #fff;
}
.boxadmin-list-article .title-basic.title-basic--red-left:before,
.boxadmin-list-article .title-basic.title-basic--red-right:before {
    background: transparent url("../../../images/ahacz/ribbon-right-red.png") no-repeat right bottom;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -10px;
    content: "";
    display: block;
}
.boxadmin-list-article .title-basic.title-basic--red-left:before {
    background: transparent url("../../../images/ahacz/ribbon-left-red.png") no-repeat right bottom;
    left: 0;
}
.boxadmin-list-article .title-basic.title-basic--red-right:before {
    right: 0;
}
.boxadmin-list-article .title-basic.title-basic--section-heading {
    width: calc(100% - 5px);
    position: relative;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 2em;
    font-family: "Helvetica Neue", Helvetica, Arial;
    font-stretch: normal;
    font-stretch: condensed;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #fff;
    background: #ff001e;
    -webkit-box-shadow: 0 2px 4px #aaa;
    box-shadow: 0 2px 4px #aaa;
    margin: 15px 0 5px;
    display: block;
    height: 2em;
    padding-left: 5px;
}
.boxadmin-list-article.purple-box .title-basic.title-basic--section-heading {
    background: #9b59b6;
}
.boxadmin-list-article .list-article--horizontal-small:first-of-type {
    margin-top: 10px;
}
.boxadmin-list-article .list-article--horizontal-small {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    clear: both;
}
.boxadmin-list-article .list-article--horizontal-small .category-name a {
    padding: 0 10px;
    font-size: 9px;
    font-weight: bold;
    line-height: 24px;
    color: #ff001e;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
}
.boxadmin-list-article .list-article--horizontal-small .category-name a:hover {
    color: #dc0032;
}
.boxadmin-list-article .list-article--horizontal-small .image-container {
    float: left;
    width: 120px;
    height: 68px;
    margin-right: 10px;
}
.boxadmin-list-article .list-article--horizontal-small .image-container img {
    width: 120px;
    height: 68px;
}
.boxadmin-list-article .list-article--horizontal-small .title-container h2 {
    margin: 0;
    padding: 3px 10px 3px 0;
    height: 64px;
    font-size: 0.875em;
    line-height: 1.25;
}
.boxadmin-list-article .list-article--horizontal-small .title-container h2 a {
    color: #343f4b;
    text-decoration: none;
}
.boxadmin-list-article .list-article--horizontal-small .title-container h2 a.arrow {
    position: relative;
    background: transparent url("../../../images/ahacz/mobile/arrow-right.png") no-repeat 99% 50%;
    display: block;
    padding-right: 25px;
}
.boxadmin-list-article.purple-box .list-article--horizontal-small .category-name a {
    color: #9b59b6;
}
.boxadmin-list-article.purple-box .list-article--horizontal-small .category-name a:hover {
    color: #734187;
}
.boxadmin-list-article.purple-box .list-article--horizontal-small .title-container h2 a.arrow {
    background: none;
}
.boxadmin-list-article.purple-box .list-article--horizontal-small .title-container h2 a.arrow:after {
    content: " ";
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;
    background: url("../../../images/ahacz/zaha-sprite.png?v=14") -60px -30px no-repeat transparent;
}
.exchange-panel {
    padding: 0 10px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}
.exchange-panel .dataimg-triangle-down,
.exchange-panel .dataimg-triangle-right,
.exchange-panel .dataimg-triangle-up {
    display: inline-block;
}
.exchange-panel .pull-right {
    float: right;
}
.exchange-panel a {
    font-weight: 700;
    font-size: 14px;
    color: #d0112b;
    text-decoration: none;
}
.exchange-panel a:focus,
.exchange-panel a:hover {
    text-decoration: underline;
}
.exchange-panel table {
    float: left;
    width: 70%;
    font-size: 13px;
    text-align: center;
}
.exchange-panel table tr {
    border-bottom: 1px solid #dcdcdc;
}
.exchange-panel table tr:last-child {
    border-bottom: 0;
}
.exchange-panel .exchange-panel__header {
    margin: 8px 0;
}
.exchange-panel__flag {
    width: 30px;
}
.exchange-panel__flag span {
    display: block;
}
.exchange-panel__currency {
    padding: 4px 0;
    font-weight: 700;
}
.exchange-panel__currency a {
    color: #1e1e1e;
}
.exchange-panel__calculator {
    float: left;
    width: 30%;
    text-align: right;
}
.exchange-panel__calculator a {
    font-size: 13px;
}
.exchange-panel__calculator .dataimg-calculator {
    width: 35px;
    margin: 0 25px;
    float: right;
}
.exchange-panel .exchange-panel__body::after,
.exchange-panel .exchange-panel__body::before {
    content: "";
    display: table;
}
.exchange-panel .exchange-panel__body::after {
    clear: both;
}
.container.blesk-exchange-panel {
    margin-bottom: 15px;
}
.loading-placeholder {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
}
.loading-placeholder img {
    width: 100%;
    opacity: 0;
    transition: opacity 1s;
    height: auto;
}
.loading-placeholder.item-loaded {
    padding-bottom: 0 !important;
}
.loading-placeholder.item-loaded img {
    opacity: 1;
}
.loading-placeholder.item-loaded img.placeholder-img-auto {
    height: auto;
}
img.lazyload {
    height: auto;
}
.loading-placeholder:not(.item-loaded) {
    position: relative;
    height: auto;
    background-color: #e8e8e8;
}
.loading-placeholder:not(.item-loaded)::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #e8e8e8;
    background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
    background-repeat: repeat-y;
    background-size: 100% 1px;
}
.loading-placeholder:not(.item-loaded) img {
    position: absolute !important;
    text-indent: -9999px;
    color: transparent;
}

.wiki-title a {
    line-height: 2em;
    font-size: 35px;
    line-height: 45px;
    color: #343f4b;
    text-decoration: none;
    margin-left: 10px;
}
h1.wiki-search__heading {
    font-size: 1.125em;
}
